// Import Assets
import profile from '../assets/profile.png';

const Header = () => {
    return (
        <section className='header'>
            <img src={profile} alt="Kylie-Rose" />

            <div className='header__content'>
                <h1>WELCOME!</h1>
                <p>Go ahead check out my virtual site that showcases my NFTs & GALLERY.</p>
                <div> 
                    <p>Would love to hear from you so connect with me on my social page!</p>
                </div>
                                
            </div>
        </section>
    );
}

export default Header;