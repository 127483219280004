// Import Assets
import showcaseOP from '../assets/showcaseOP.png';
import showcasecrypto from '../assets/showcasecrypto.png';
import showcaseshoes from '../assets/showcaseshoes.png';

const Projects = () => {
    return (
        <section className="projects">
            <h2>My NFT Projects</h2>

            <div className="projects__cards">

                <div className="projects__card">
                    <h3>Open Punks</h3>
                    <img src={showcaseOP} alt="Open Punks Landing Page" />
                    <p>This 1,000 generated punked out images using an art generator is FREE.  We are giving the images
                     for free for educational & demonstration purpose.
                     <p>   
                      Teaching you about wallets is crucial to participating the cryptocurrency/NFT 
                     commerce! 
                     </p>   
                    </p>

                    <a href="https://dark-mouse-9415.on.fleek.co" target="_blank" className="button">Site</a>
                </div>
                <div className="projects__card">
                    <h3>Crypto Inspired</h3>
                    <img src={showcasecrypto} alt="Crypto Inspired Landing Page" />
                    <p>My mom definately inspired this collection - her desire for me to learn & participate in crypto economics.  It makes
                    sense that I do crypto inspired art pieces.  
                    <p>As a teen, I wanted this collection to reflect my personality and connect with community that are like minded!  
                    </p>
                    </p>

                    <a href=""  target="_blank" className="button">COMING IN JULY!</a>
                </div>

                <div className="projects__card">
                    <h3>Shoes with Personality!</h3>
                    <img src={showcaseshoes} alt="Shoes with Personality Landing Page" />
                    <p>Mood changes... feel like wearing this ???
                        As a teen, we like to change our shoes as our mood changes ;)
                        We definately like changing our style with the change of season.  Versatility is key!
                     <p>   
                     Girls just wanna have fun... with her shoes!  Be versatile with your NFT & I'll show you how to customize your shoes 
                     in my social media page. 
                    </p>
                    </p>

                    <a href=""  target="_blank" className="button">COMING IN JULY!</a>
                </div>
            </div>
        </section>
    );
}

export default Projects;