const Experience = () => {
    return (
        <div className="experience">
            <h2>Gallery</h2>

            <ul>
                <li>
                    <i>NFT #1</i> - <strong>Open Punks IMAGES</strong> >>>   
                    <a href="https://testnets.opensea.io/collection/openpunks-free" target="_blank">Link to OpenSea</a>
                    <ul>
                        <li>OpenSea is where you will find all the OpenPunks NFT images that have been minted.  You can 
                        trade/sell your own NFT images that you minted.</li>
                    </ul>
                </li>
                <li>
                    <i>NFT #2</i>,  <strong>Crypto Inspired IMAGES</strong> >>> Link to NFT Market Place (COMING SOON!)
                    <ul>
                        <li>More information here!</li>
                    </ul>
                </li>
                <li>
                    <i>NFT #3</i> - <strong>Custom Shoes IMAGES</strong> >>> Link to NFT Market Place (COMING SOON!)
                    <ul>
                        <li>More information here!</li>
                        
                    </ul>
                </li>
            </ul>
        </div>
    );
}

export default Experience;