const Social = () => {
    return (
        <div className="social">
            <h2>Socials</h2>
              <div> 
                <p>Keep checking my social page since I will be posting announcements of upcoming NFT Collections.  Also  
                don't want to miss out on any promotional offerings and activities so follow me on my social page!
                </p>
              </div>
              <div>
                <p>
                <a href="mailto:info@kylie-rose.com" className="button">Email Kylie-Rose</a>
                <a href="https://twitter.com/4Kylie_Rose" target="_blank" className="button">Twitter</a>
                <a href="https://discord.com/4Kylie-Rose" target="_blank" className="button">Discord</a>
                </p>  
               
              </div>

        </div>
    );
}

export default Social