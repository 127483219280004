// Import Assets
import profile from '../assets/profile.png';

const About = () => {
    return (
        <section className='about'>
            <h2>About Me</h2>
            <div>
            
              <p> Hi, I'm Kylie and I'm 14 years old. What inspired me to do ART? ... Friends & Family!  Seeing the 
                joy of the recipient of my masterpiece is PRICELESS! 
            
              <p> The story behind Kylie-Rose NFT MarketPlace...  </p> </p>
              <ul>
                <li>
                    <i>I wanted to earn some money coz I'm a teenage you know ;) shhh !  ... so I started doing customized sketches for
                        my teammate' shoes, greeting cards and paintings for family & friends.
                    </i> 
                </li>           
                <li>
                    <i>My mom wanted me to learn WEB3/Blockchain/cryptocurrency economy as I participate in NFT commerce. 
                This is the future of money, so I might us well learn & earn cryptos while doing what I like/love ~ ART!
                    </i>
                </li>    
                <li>
                    <i>Also, being in competitive gymnastics which I love, earning cryptocurrency by participating in WEB3/NFT MarketPlace
                 will help pay for my competitive gymnastic expenses. So its a WIN-WIN!  I give you my labor of LOVE with ART,
                  while helping me finance my LOVE for gymnastics. This is my entrepreneurial version of sponsorship or go-fund-me... you 
                    getting an ART piece for your crypto donation!
                    </i>
                </li>             
              </ul>

                <p>My FUTURE is bright if I like/love what I do, so if you see something you like or want a custom design, please don't hesitate 
                contact me in my social page. You can contribute directly, but I would prefer that you get something from any one of my 
                labor of love ART collection!  Thank you in advance for your participation, contribution and all support.
                </p>
               
            </div>                
            
        </section>
    );
}

export default About;